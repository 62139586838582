import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { colors, fontSize, fontWeight, screenSize } from './variables';

const SPECIAL_THEMES = ['blue', 'red'];

export const getLinkStyle = theme => `
  text-decoration: none;
  color: ${SPECIAL_THEMES.includes(theme) ? colors.grayLight : colors.red};
  ${SPECIAL_THEMES.includes(theme) && `
    border-bottom: 1px solid ${colors.grayLight};
  `}

  @media (max-width: ${screenSize.xsMax}) {
    border-bottom: 1px solid ${
      SPECIAL_THEMES.includes(theme) ? colors.grayLight : colors.red
    };
  }

  &:hover {
      color: ${SPECIAL_THEMES.includes(theme) ? colors.white : colors.redDark};
      border-bottom: 1px solid ${
        SPECIAL_THEMES.includes(theme) ? colors.white : colors.redDark
      };
  }
`;

export const H1 = styled.h1`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.default};
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const Title = styled.h2`
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.default};
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const Subtitle = styled.h3`
  font-size: ${fontSize.default};
  font-weight: ${fontWeight.default};
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

export const DefaultExternalLink = styled.a`
  ${getLinkStyle()}
`;

export const DefaultLink = styled(Link)`
  ${getLinkStyle()}
`;

export const Markdown = styled(ReactMarkdown)`
  white-space: pre-line;

  ul {
    margin: 0;
  }

  a {
    ${props => getLinkStyle(props.theme)};
  }

  strong {
    font-weight: ${fontWeight.default};
  }
`;
